import {
  rdbV1TrendingListsList,
  v1HeadlinesRelatedHeadlinesDetail,
  type V1HeadlinesRelatedHeadlinesDetailParams,
} from '@on3/api';
import withAuthentication from '@on3/ui-lib/src/hocs/withAuthentication';
import withValidUrl from '@on3/ui-lib/src/hocs/withValidUrl';
import { getFulfilledValue, proxyApi } from '@on3/ui-lib/utils/api';
import { ArticleShell } from 'components/Headlines/Article/ArticleShell';
import { IArticleWrapperProps } from 'components/Headlines/Article/ArticleWrapper';
import type { GetServerSideProps } from 'next';
import { getArticleData } from 'utils/getPromiseData';

const NetworkArticlePage = (props: IArticleWrapperProps) => {
  return <ArticleShell {...props} key={props?.article?.key} />;
};

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const siteData = await withValidUrl.isValid(ctx);
  const userData = await withAuthentication.isAuth(ctx);

  if (!siteData) {
    return {
      notFound: true,
    };
  }

  const mainCategory = siteData?.currentSite?.siteCategories?.find(
    (siteCategory) => siteCategory.url === '',
  );

  const { slug } = ctx.query;

  if (!mainCategory?.categoryKey) {
    console.error({ url: ctx?.req?.url, error: 'category null' });

    return {
      notFound: true,
    };
  }

  try {
    const url = `/content/v1/categories/${mainCategory?.categoryKey}/headlines/${slug}/`;

    const response = await getArticleData(url, userData);

    if (response?.statusCode >= 400) {
      return {
        notFound: true,
      };
    }

    if (response?.statusCode >= 300) {
      return {
        redirect: {
          destination: response?.resource?.path || '/',
          permanent: true,
        },
      };
    }

    const fetchedArticle = response || null;
    const params: V1HeadlinesRelatedHeadlinesDetailParams = {
      headlineKey: fetchedArticle?.key,
    };

    const [relatedArticles, trendingList] = await Promise.allSettled([
      v1HeadlinesRelatedHeadlinesDetail(proxyApi, params),
      rdbV1TrendingListsList(proxyApi, {
        trendingListKey: 1,
        siteKey: 44,
        limit: 5,
      }),
    ]);

    const data = {
      pageType: 'article',
      siteData: siteData || [],
      userData: userData || {},
      article: fetchedArticle,
      relatedArticles: getFulfilledValue(relatedArticles),
      trendingList: getFulfilledValue(trendingList),
    };

    return { props: { ...data } };
  } catch (e) {
    return { props: {} };
  }
};

export default withAuthentication(withValidUrl(NetworkArticlePage));
